<template>
  <custom-selector
    class="courier-hub-user-group-selector"
    v-bind="$attrs"
    :items="userGroups"
    :formattedValue="formattedValue"
    v-on="$listeners"/>
</template>

<script>
const CustomSelector = () => import('@components/CustomSelector')

export default {
  name: 'courier-hub-user-group-selector',
  components: {
    CustomSelector
  },
  props: {
    ...CustomSelector.props,
    userGroups: {
      type: Array,
      required: true
    }
  },
  computed: {
    formattedValue() {
      const isMultiple = this.$attrs.multiple || this.$attrs.multiple === '' // empty string counts as true for props
      if (isMultiple) {
        return this.$attrs.value.join(', ')
      }
      return this.$attrs.value
    }
  }
}
</script>
